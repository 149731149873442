@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    transition: 0.3s all;
}

table *,
svg,
path {
    transition-property: none;
}

/* body::-webkit-scrollbar{
    display: none;
} */

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1f2d3d;
    border-radius: 10px;
}

/* Input Pointer Button */
button,
a,
i,
input[type="submit"],
input[type="button"] {
    cursor: pointer;
}

/* Popup */

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
}
